export default function UserAgreement() {
    return (
      <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-6 text-gray-900">Угода користувача</h1>
        <p className="text-lg text-gray-700 mb-4">
          Ця Угода користувача (далі – Угода), адресована невизначеному колу осіб, відвідувачам сайту <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a> (далі – «Сайт»), зокрема неавторизованим та авторизованим користувачам та замовникам.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Ця Угода є публічною офертою згідно з чинним законодавством України і регулює використання Сайту, який є власністю ФОП Афоніна Оксана Валентинівна, власниці клінінгової компанії “Ad Clavum“.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li><strong>1.1. Угода</strong> – дійсна Угода користувача з урахуванням всіх змін та доповнень.</li>
          <li><strong>1.2. Користувач</strong> – фізична особа, яка приєдналась до цієї Угоди в особистому інтересі, використовуючи цей Сайт.</li>
          <li><strong>1.3. Сайт</strong> – офіційний сайт ФОП Афоніна Оксана Валентинівна, який розміщений за адресою <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a> та містить дані про послуги клінінгу, навчання та іншу інформацію про прибирання, яка сприймається як єдине ціле, та підтримується комплексом системних програмних засобів.</li>
          <li><strong>1.4. Сервіс</strong> – комплекс послуг, що надаються Користувачеві з використанням Сайту.</li>
          <li><strong>1.5. Аккаунт</strong> – обліковий запис Користувача – відвідувача сайту <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a>, який розширює Сервіси сайту для користування.</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">ЗАГАЛЬНІ ПОЛОЖЕННЯ</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li>Користувач зобов’язаний ознайомитися з вимогами даної Угоди та прийняти умови Політики конфіденційності.</li>
          <li>Угода обов’язкова для дотримання і виконання всіх користувачам Сайту (авторизованим та не авторизованим).</li>
          <li>Користувач не має права користуватися Сайтом, якщо не прийняв умови даної Угоди з причин недосягнення встановленого законодавством України віку, коли він має право укласти таку угоду.</li>
          <li>У випадку заборони користуватися даним Сайтом законодавством іноземної держави, де проживає або перебуває Користувач з будь-яких причин, Користувач повинен дотримуватися таких вимог, а якщо порушує такі вимоги, несе особисту відповідальність.</li>
          <li>Дана Угода підлягає зміні або доповненню Адміністрацією Сайту без додаткового повідомлення. Дана Угода є публічною і опублікованою.</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">УМОВИ АВТОРИЗОВАНОГО КОРИСТУВАННЯ</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li>Авторизовані Користувачі використовують Сервіси сайту <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a> і мають можливість коментувати дописи в блозі цього сайту.</li>
          <li>Реєстрація Аккаунту для доступу на Сайт здійснюється автоматично, або одобрюється адміном. На розсуд Адміністрації сайту.</li>
          <li>Користування Сервісами Сайту можливе без реєстрації. Реєстрація потрібна тільки для написання коментарів.</li>
          <li>Логін і пароль для авторизації персоніфіковані та обираються Користувачем.</li>
          <li>Встановлюючі паролі для авторизації Сайту, Користувач несе особисту відповідальність за правильність та коректність доступу до Сервісів <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a>.</li>
          <li>Користувачу забороняється надання доступу до Аккаунту третім особам.</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">ВИКОРИСТАННЯ МАТЕРІАЛІВ САЙТУ</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li>Графічні, текстуальні та інші матеріали Сайту <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a>, як і весь сайт в цілому, захищений та охороняється українським законодавством про авторське право та суміжні права.</li>
          <li>Пріоритет в захисті прав на опубліковані матеріали надаються Власникам, Адміністрації Сайту, авторам, а вже потім третім особам, відповідно до обґрунтованості претензій останніх.</li>
          <li>Користувач Сайту не може завантажувати чи редагувати будь-які матеріали, окрім своїх коментарів.</li>
          <li>Забороняється в комерційних цілях відтворювати, поширювати, передавати третім особам, опублікувати або іншим чином використовувати матеріали, завантажені з Сайту, без згоди Власника, Адміністрації Сайту, окрім контактних даних.</li>
          <li>У разі копіювання, розповсюдження, опублікування або іншого використання матеріалів Сайту, що охороняються авторським правом, такі дії, без наявності відповідного гіперпосилання на першоджерело, забороняються і переслідуються за законом.</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">ВИМОГИ ДО ПУБЛІКАЦІЇ КОМЕНТАРІВ НА САЙТІ</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li>Авторизовані користувачі мають змогу особисто розміщувати коментарі на Сайті та надають свою згоду Власнику, Адміністрації Сайту на розповсюдження зазначеного контенту.</li>
          <li>Сторінки з матеріалами Сайту можуть містити рекламну інформацію на комерційній основі.</li>
          <li>Розміщенні коментарі із порушенням законодавства про авторське право і відсутності згоди законного правовласника, будуть видалені.</li>
          <li>Опубліковані коментарі на Сайті не повинні містити:
            <ul className="list-disc list-inside text-lg text-gray-700 mt-2">
              <li>матеріалів, що ображають честь, гідність і ділову репутацію;</li>
              <li>обмеження прав меншин;</li>
              <li>матеріалів, які порушують закон;</li>
              <li>матеріалів, що порушують права на комерційну таємницю, копірайт та/або авторські й суміжні права третьої сторони;</li>
              <li>нав’язливої реклами товару або послуг;</li>
            </ul>
          </li>
          <li>При наявності письмових претензій від третіх осіб, пов’язаних із розміщеним Користувачем коментарем, Користувач особисто і за власний кошт врегульовує пред’явлені претензії.</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">ПРАВА ТА ОБОВ’ЯЗКИ</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li>Адміністрація Сайту приймає всіх заходів для роботи ресурсу належної якості, проте відповідальність за втрату коментарів, що опубліковують Користувачі не несе.</li>
          <li>Користувач має право розміщати за допомогою Сайту тексти як об’єкти інтелектуальної власності, право на використання яких належать йому на законних підставах.</li>
          <li>Адміністрація Сайту залишає за собою право відмовити Авторизованому Користувачу в публікації контенту, а також вилучити тексти за власним розсудом, якщо їхній зміст суперечить вимогам цієї Угоди.</li>
          <li>Автор текстів (авторизований користувач) відповідає за контент, що публікується останнім на <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a>.</li>
          <li>Адміністрація Сайту не несе відповідальності за зміст контенту та його відповідність вимогам діючого законодавства, зокрема порушення авторського права і суміжного права, а також порушення прав третіх осіб. Претензій третіх осіб, що стосуються опублікованого контенту, Автор статей (авторизований користувач) самостійно і за свій рахунок врегульовує в досудовому та судовому порядку.</li>
          <li>Користувач зобов’язується не здійснювати продаж чи перепродаж, а також не використовувати в інших, в тому числі комерційних цілях об’єкти інтелектуальної власності <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a>.</li>
          <li>Сервіси <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a> можуть не відповідати всім вимогам і потребам Користувача, однак Адміністрація Сайту постійно намагається їх поліпшувати та модернізовувати.</li>
          <li>Адміністрація Сайту не повідомляє Автора коментарів про наявні претензії третіх осіб щодо опублікованого ним контенту.</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">ВІДПОВІДАЛЬНІСТЬ СТОРІН</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li>Відповідальність сторін наступає з моменту порушення або моменту, коли сторона дізналася чи могла дізнатися про порушення.</li>
          <li>Вид відповідальності встановлюється виходячи із положень діючого законодавства України, з урахуванням умислу та характеру дій, розміру заподіяної шкоди та інше.</li>
          <li><strong>7.3. Відповідальність Адміністрації сайту:</strong>
            <ul className="list-disc list-inside text-lg text-gray-700 mt-2">
              <li>Адміністрація сайту не здійснює відшкодування шкоди, прямої чи опосередкованої, яка спричинена Користувачу або третім особам у наслідок використання або невикористання, у тому числі неможливості використання Сайту.</li>
              <li>Адміністрація сайту не відповідає за гіперпосилання на інші веб-ресурси (сайти), і не надає гарантій їх доступності, коректності відображення та відповідності.</li>
            </ul>
          </li>
          <li>Усі можливі суперечки, що виникають у зв’язку та з приводу виконання цієї Угоди, вирішуються згідно з нормами чинного законодавства України.</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">ОБМЕЖЕННЯ ДОСТУПУ ДО САЙТУ</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li>У випадку виявлених фактів порушення даної Угоди та інших внутрішніх документів, що опубліковані на сайті, Адміністрація сайту залишає за собою право на обмеження доступу таких Користувачів.</li>
        </ul>
        <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">ІНШІ УМОВИ</h2>
        <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
          <li>Всі питання, що не врегульовані даною Угодою та іншими внутрішніми документами, що опубліковані на сайті, вирішуються відповідно до законодавства України.</li>
          <li>Користувач погоджується невідкладно повідомляти про будь-які порушення авторських прав, які мають місце на <a href="https://adclavumcleaning@gmail.com" className="text-blue-600 underline">adclavumcleaning@gmail.com</a> шляхом направлення листа електронною поштою adclavumcleaning@gmail.com /</li>
        </ul>
      </div>
    );
  }
  