import React from 'react';

const Contract = () => {
  return (
    <div className="container max-w-5xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold mb-4">ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ про надання клінінгових послуг</h1>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h2>
        <p className="mb-4">
          Наведені у даній оферті терміни використовуються в таких значеннях:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Оферта</strong> – цей документ, опублікований в мережі Інтернет за адресою <a href="https://adclavum.com" className="text-blue-500">https://adclavum.com</a> і містить пропозицію необмеженому колу осіб.</li>
          <li><strong>Акцепт оферти</strong> – повне і беззастережне прийняття умов оферти Замовником шляхом здійснення дій, зазначених у п.6.2. Оферти. Акцепт оферти створює Договір.</li>
          <li><strong>Веб-сайт</strong> – належний Виконавцю ресурс, розміщений для публічного доступу в мережі Інтернет за адресою: ekonomka.com.ua, що забезпечує інформування користувачів про перелік послуг, вартість, порядок їх замовлення та оплати, і надає потенційним Замовникам можливість оформити замовлення онлайн, чи по телефону.</li>
          <li><strong>Виконавець</strong> – ФОП Афоніна Оксана Валентинівна;</li>
          <li><strong>Замовник</strong> – будь-яка фізична або юридична особа, яка своїми діями виявила намір укласти договір на умовах визначених у даній Оферті.</li>
          <li><strong>Клінінгові послуги</strong> – це комплекс послуг, які надаються Виконавцем та залученими ним особами, направлений на забезпечення чистоти в Об’єкті.</li>
          <li><strong>Об’єкт</strong> – приміщення або територія, або їх частина, де безпосередньо надаються послуги клінінгу.</li>
          <li><strong>Перелік послуг</strong> – комплекс клінінгових послуг, що надаються Замовникам, опублікований на Веб-сайті Виконавця.</li>
          <li><strong>Прайс-лист на послуги</strong> – діючий систематизований перелік послуг Виконавця з цінами на послуги, опублікований на Веб-сайті Виконавця.</li>
        </ul>
        <p className="mb-4">
          Терміни, не визначені цією Офертою тлумачаться в першу чергу у відповідності до змісту цієї Оферти, в другу чергу – відповідно до чинного законодавства України, в третю чергу – відповідно до звичаїв ділового обороту.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">ЗАГАЛЬНІ ПОЛОЖЕННЯ</h2>
        <ul className="list-disc list-inside mb-4">
          <li><strong>1.1.</strong> Відповідно до статті 641 Цивільного Кодексу України цей документ є публічною офертою Виконавця та містить всі істотні умови договору з надання послуг Замовнику.</li>
          <li><strong>1.2.</strong> В разі прийняття викладених нижче умов і оплати послуг, особа вважається такою, що приймає пропозицію укласти договір з Виконавцем на визначених у даній Оферті умовах (здійснює акцепт Оферти) та стає Замовником.</li>
          <li><strong>1.3.</strong> Цей договір містить в собі умови договорів побутового підряду і надання послуг та регулюється нормами цивільного законодавства України та і Законом України «Про захист прав споживачів», щодо Замовників – фізичних осіб.</li>
          <li><strong>1.4.</strong> Здійснюючи акцепт даного публічного договору оферти, Замовник підтверджує свою правоздатність та дієздатність, досягнення Замовником віку 18 років, а також законне право вступати в договірні відносини з Виконавцем.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">ПРЕДМЕТ ОФЕРТИ. АКЦЕПТ ОФЕРТИ</h2>
        <ul className="list-disc list-inside mb-4">
          <li><strong>2.1.</strong> Виконавець зобов’язується надати Замовнику клінінгові послуги в відповідно до умов цієї оферти, а Замовник зобов’язується оплатити послуги за встановленою вартістю, обумовленою Виконавцем. Виконавець в залежності від дійсного стану Об’єкта має право скоригувати вартість послуг шляхом додаткового оголошення остаточної вартості Клінінгових послуг. Таке оголошення остаточної вартості Клінінгових послуг буде вважатись невід’ємною частиною Оферти.</li>
          <li><strong>2.2.</strong> Вид послуги та її детальний опис зазначається на Веб-сайті Виконавця у розділі “Послуги”. Сторони можуть погодити окремий вид та перелік послуг з клінінгу, що не передбачений у зазначеному розділі. При цьому інші умови Оферти залишатимуться незмінними.</li>
          <li><strong>2.3.</strong> Виконавець публікує на своєму Веб-сайті Прайс-лист на послуги. Але кінцева ціна визначається після огляду і обумовлення всих деталей.</li>
          <li><strong>2.4.</strong> Цей договір публічної оферти вважається укладеним (акцепт оферти) з моменту оголошення замовлення Замовником.</li>
          <li><strong>2.5.</strong> Замовляючи послуги, Замовник дає згоду на отримання інформації від Виконавця за допомогою sms та e-mail- розсилок, а також повідомлень в месенджерах та соціальних мережах.</li>
          <li><strong>2.6.</strong> Виконавець має право залучати до виконання цього Договору третіх осіб, залишаючись при цьому відповідальним за їх дії перед Замовником.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">УМОВИ І ПОРЯДОК НАДАННЯ ПОСЛУГ</h2>
        <ul className="list-disc list-inside mb-4">
          <li><strong>3.1.</strong> Для замовлення послуг Виконавця Замовник надсилає відповідне повідомлення (sms, повідомлення через месенджери Viber, WhatsApp) на телефонні номери зазначені на Веб-сайті Виконавця або здійснює безпосередній телефонний дзвінок, чи замовляє зворотній дзвінок шляхом заповнення відповідної форми на Веб-сайті.</li>
          <li><strong>3.2.</strong> В результаті здійснення дій, передбачених в п. 3.1. з Замовником зв’язується представник Виконавця і погоджує з ним місце, об’єм послуг, вартість, дату і часовий інтервал для виконання Замовлення.</li>
          <li><strong>3.3.</strong> У разі погодження всіх умов Замовлення і якщо Замовник вказав, що вартість послуг погоджена з ним, або інше підтвердження акцепту, то оформлення Замовлення вважається завершеним і Договір укладеним.</li>
          <li><strong>3.4.</strong> Всі послуги надаються згідно Прайс-листа та на основі інформації, зазначеної в описі кожного з них на Веб-сайті.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">ПРАВА І ОБОВ'ЯЗКИ СТОРІН</h2>
        <ul className="list-disc list-inside mb-4">
          <li><strong>4.1.</strong> Виконавець зобов’язується:</li>
          <ul className="list-disc list-inside mb-4">
            <li>4.1.1. Надати Замовнику якісні клінінгові послуги згідно з узгодженим графіком і в межах зазначеного обсягу.</li>
            <li>4.1.2. Виконувати роботу з урахуванням сучасних стандартів і з використанням професійних засобів та обладнання.</li>
            <li>4.1.3. У разі необхідності, повідомити Замовника про можливі зміни в графіку або вартості послуг.</li>
          </ul>
          <li><strong>4.2.</strong> Замовник зобов’язується:</li>
          <ul className="list-disc list-inside mb-4">
            <li>4.2.1. Своєчасно оплатити надані послуги відповідно до встановленого Прайс-листу та в межах узгодженої вартості.</li>
            <li>4.2.2. Надати доступ до Об’єкта для виконання клінінгових послуг у визначений час.</li>
            <li>4.2.3. Повідомити Виконавця про особливості Об’єкта, які можуть вплинути на надання послуг.</li>
          </ul>
          <li><strong>4.3.</strong> Виконавець має право:</li>
          <ul className="list-disc list-inside mb-4">
            <li>4.3.1. Вимагати від Замовника своєчасної оплати за надані послуги.</li>
            <li>4.3.2. Вносити зміни до розкладу надання послуг за погодженням з Замовником у разі необхідності.</li>
          </ul>
          <li><strong>4.4.</strong> Замовник має право:</li>
          <ul className="list-disc list-inside mb-4">
            <li>4.4.1. Перевіряти якість наданих послуг і вимагати усунення недоліків, якщо такі є.</li>
            <li>4.4.2. Отримувати консультації і допомогу з питань, що виникають у процесі надання послуг.</li>
          </ul>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">ВІДПОВІДАЛЬНІСТЬ СТОРІН</h2>
        <ul className="list-disc list-inside mb-4">
          <li><strong>5.1.</strong> Виконавець несе відповідальність за якість наданих послуг і за шкоду, завдану в результаті неналежного виконання своїх обов’язків.</li>
          <li><strong>5.2.</strong> Замовник несе відповідальність за своєчасну оплату послуг і за надання вірної інформації про Об’єкт та його особливості.</li>
          <li><strong>5.3.</strong> Сторони звільняються від відповідальності за невиконання або неналежне виконання зобов’язань, якщо це сталося внаслідок обставин непереборної сили.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">РІЗНІ ПОЛОЖЕННЯ</h2>
        <ul className="list-disc list-inside mb-4">
          <li><strong>6.1.</strong> Всі спори, що виникають у зв’язку з виконанням цього Договору, вирішуються шляхом переговорів. У разі недосягнення згоди, спори підлягають розгляду в суді відповідно до чинного законодавства України.</li>
          <li><strong>6.2.</strong> Договір набирає чинності з моменту його акцепту Замовником і діє до моменту виконання всіх зобов’язань сторонами.</li>
          <li><strong>6.3.</strong> Усі зміни і доповнення до цього Договору можливі лише за взаємною письмовою згодою сторін.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">АДРЕСИ І РЕКВІЗИТИ СТОРІН</h2>
        <ul className="list-disc list-inside mb-4">
          <li><strong>7.1.</strong> Адреса Виконавця: [Вкажіть адресу]</li>
          <li><strong>7.2.</strong> Адреса Замовника: [Вкажіть адресу]</li>
        </ul>
      </section>
    </div>
  );
};

export default Contract;
