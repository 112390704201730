import React from 'react';

export default function Privacy() {
  return (
    <div className="max-w-5xl mx-auto p-6 my-5 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4 text-gray-900">Політика конфіденційності</h1>
      <p className="text-lg text-gray-700 mb-4">
        Ця Політика конфіденційності регулює реалізацію практики збору інформації з клінінгова компанія “Ad Clavum“, юридична назва “ФОП Афоніна Оксана Валентинівна“ 
        (“ФОП Афоніна Оксана Валентинівна“, “ми” або “нас”). Зокрема, він визначає типи інформації, яку ми збираємо за час, коли ви використовуєте наш веб-сайт (далі – “Сайт”), а також методи використання зібраної інформації з нашої частини.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Ми просимо вас ретельно ознайомитись із реальною політикою конфіденційності. Відвідавши та використовуючи Сайт, ви погоджуєтесь з тим, що використання Сайту вас та будь-яких суперечок та розбіжностей щодо конфіденційності, регулюються цією Політикою конфіденційності.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">Як ми збираємо інформацію</h2>
      <p className="text-lg text-gray-700 mb-4">
        Ми можемо збирати, зберігати та обробляти особисту та іншу інформацію, надану вами, за допомогою нашого Сайту. Наприклад, ФОП Афоніна Оксана Валентинівна може зв’язатися з особами, які подали заявку на послуги, а також осіб, які зв’язалися з нами, надаючи свою інформацію, щоб отримати відповідь на свої запитання.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        ФОП Афоніна Оксана Валентинівна збирає від наших користувачів персональну ідентифікаційну інформацію через сторінки Сайту та час від часу можуть з’явитися інші сторінки, які збирають подібну інформацію. Загалом, ця інформація включає повне ім’я номера телефону, адреси електронної пошти, паролі та інформацію про працевлаштування, щоб зареєструватися або вибрати послуги.
      </p>

      <p className="text-lg text-gray-700 mb-4">
        Файли cookie – це невеликі файли, які ваш веб-браузер зберігає на жорсткому диску вашого комп’ютера, щоб підтримувати документацію. Показуючи, як і коли користувачі користуються Сайтом, файли cookie допомагають нам визначити, скільки унікальних користувачів відвідують наш Сайт, відстежують їх тенденції та схеми та можуть допомогти у проведенні рекламних кампаній або доставці інших сповіщень.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Вони також можуть звільнити вас від необхідності переоцінки ваших уподобань у певних областях сайту, де ви вже ввели свої уподобання щодо інформації раніше. Файли cookie можна зберігати, поки ви не видалите їх вручну або не встановите відмову, щоб зберегти їх у своєму браузері. У разі відмови прийняти файли cookie, у вас можуть виникнути труднощі навігації на веб-сайті, або показники ефективності можуть відрізнятися. Сайт також може використовувати веб-маяки (елементарний графічний файл, також відомий як “прозорий файл GIF”) для доступу до файлів файлів cookie та розгляду користувачів, які відвідують сайт або відкривають електронні повідомлення у форматі HTML.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">Як ми використовуємо інформацію</h2>
      <p className="text-lg text-gray-700 mb-4">
        Ми можемо використовувати інформацію, зібрану від вас, у той час, коли ви використовуєте Сайт, різними способами, включаючи використання інформації для налаштування функцій або реклами, які можуть відображатися на сайті. ФОП Афоніна Оксана Валентинівна може також ділитися інформацією, яку ви надали нам, з підрядниками, які можуть використовувати вашу інформацію, щоб зв’язатися з вами як з потенційним клієнтом або з подібним діловим наміром.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Ми також можемо надати вашу інформацію третім сторонам як процесорам даних або іншим постачальникам послуг або рекламодавцям, щоб покращити якість вашого досвіду взаємодії з нами, із Сайтом, або з ФОП Афоніна Оксана Валентинівна, або надати вам інформацію про відповідні товари та послуги.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Якщо ви не хочете, щоб ваша інформація була використана для будь-якої з наведених вище цілей, напишіть електронне повідомлення на наш е-мейл та вкажіть цілі, в яких ви хочете заборонити використовувати свою інформацію. Ми просимо вас дати нам розумний проміжок часу, щоб видалити вашу інформацію з цих цілей, і з огляду на це припинення використання інформації для цих цілей не може бути негайним.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Більше того, може розкрити вашу інформацію, за шкалою, необхідною законодавством, або за шкалою, в якій ми вважаємо за необхідне захистити наші юридичні інтереси. Ми залишаємо за собою право співпрацювати з державними органами та розкривати таку інформацію, щоб відповідати вимогам юридичного процесу.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Сайт також може включати посилання на інші веб-сайти або надавати доступ до продуктів та послуг, пропонованих третіми сторонами, політика конфіденційності яких не знаходиться під нашим контролем. Коли ви відвідуєте інший веб-сайт або купуєте товари чи послуги третіх сторін, використання будь-якої інформації, що надається вами, регулюється політикою конфіденційності сайту, який ви відвідуєте, який надає такі товари чи послуги або збирає свою інформацію.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">Використання інструментів аналізу Google</h2>
      <p className="text-lg text-gray-700 mb-4">
        Ми також можемо скористатися функціональністю рекламодавця інструментів аналізу Google (або подібних служб) для оптимізації його діяльності.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Функціональність рекламодавця включає:
      </p>
      <ul className="list-disc list-inside text-lg text-gray-700 mb-4">
        <li>Повторний маркетинг за допомогою інструментів аналізу Google;</li>
        <li>Повідомлення про враження від контекстної медичної мережі Google;</li>
        <li>Інтеграція з платформою DoubleClick (двічі клацання);</li>
        <li>Демографічна звітність та звітність інтересу інструментів аналізу Google.</li>
      </ul>
      <p className="text-lg text-gray-700 mb-4">
        Після включення функцій інструментів Google Analytics нам потрібно повідомити відвідувачів, розкриваючи інформацію про використання цих функцій та про те, що ми та файли cookie третіх сторін (наприклад, інструменти аналізу Google) або інші основні ідентифікатори, а також файли cookie третіх сторін (наприклад, файли cookie DoubleClick) або інші ідентифікатори третіх сторін збиратимуть дані про вашу діяльність на нашому Сайті. Серед інших методів використання, це дозволяє нам зв’язатися з вами, якщо ви почнете заповнювати нашу тестову форму, але перестаньте робити це в процесі заповнення. Цей контакт можна здійснити за допомогою електронного повідомлення з нагадуванням про завершення заповнення вашого замовлення.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Функція повторного маркетингу дозволяє нам зв’язатися з особами, які раніше відвідували наш веб-сайт, та порівняти правильну аудиторію з правильним рекламним повідомленням. Ви можете відмовитися від використання файлів cookie компанією Google, відвідавши налаштування реклами Google, та/або ви можете відмовитися від використання файлів cookie третіми сторонами, відвідавши сторінку відмови Network Advertising Initiative.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">Розташування вашої інформації</h2>
      <p className="text-lg text-gray-700 mb-4">
        Ваші особисті дані можуть бути оброблені нами в Україні відповідно до вимог чинного законодавства. Погоджуючись з політикою цієї конфіденційності, ви даєте однозначну та безумовну згоду на збір та обробку будь-якої особистої та неособистісної інформації, яка була зібрана або отримана від ФОП Афоніна Оксана Валентинівна на міжнародному рівні.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">Зберігання вашої інформації</h2>
      <p className="text-lg text-gray-700 mb-4">
        Ваша інформація буде зберігатися на серверах ФОП Афоніна Оксана Валентинівна, що забезпечує збереження наших даних, а обмін повідомленнями буде зберігатися до тих пір, поки ця інформація не буде корисною, більше не буде. Ваша інформація буде доступна лише для нас та тих компаній, які надають допомогу в управлінні Сайтом.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">Електронний маркетинг та відхилення від нього</h2>
      <p className="text-lg text-gray-700 mb-4">
        Наскільки це вимагається Законом про боротьбу з агресивною несанкціонованою порнографією та рекламою 2003 року (Закон CAN-SPAM) або іншими подібними внутрішніми міжнародними нормативними актами, усі комерційні електронні листи, надіслані вам нами, міститимуть посилання для скасування підписки. Ви можете видалити себе з нашого списку розсилки в будь-який час, натиснувши посилання для скасування підписки в кожному повідомленні, яке ми вам надсилаємо.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">Ваші права на конфіденційність</h2>
      <p className="text-lg text-gray-700 mb-4">
        Ви маєте право на доступ, транспортування, оновлення, виправлення чи видалення своїх особистих даних, а також право оскаржити або обмежити їх використання або скасувати їх використання. Ці права можна реалізувати, надіславши електронний лист на адресу: adclavumcleaning@gmail.com.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        Ви також маєте право подати скаргу до місцевих органів із захисту даних, якщо ви думаєте, що ваші права на конфіденційність даних щодо вашої особистої інформації не дотримуються належним чином.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        У вашому повідомленні, надісланому нам, ви повинні включити достатню кількість інформації, щоб ми знайшли ваш особистий файл, включаючи принаймні ваше повне ім’я, електронну адресу та поштову адресу. Ми спробуємо надати вам необхідну інформацію протягом тридцяти (30) днів після отримання. Ми залишаємо за собою право не відповідати на запити, надіслані більше одного разу протягом одного календарного року, або на запити інформації, відмінної від зазначеної в цьому повідомленні. Будь ласка, зверніть увагу, що закон про особисту інформацію не поширюється на всі види зв’язку, і ми можемо розкривати лише інформацію, яка вимагається законом.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-900">Оновлення Політики конфіденційності</h2>
      <p className="text-lg text-gray-700 mb-4">
        ФОП Афоніна Оксана Валентинівна залишає за собою право оновлювати цю Політику про конфіденційність на власний розсуд. Ви можете дізнатися чи оновлювалася ця Політика конфіденційності з часу вашого відвідування, переглянувши «Дату набрання чинності чинною політикою» в нижній частині цієї сторінки. Використання нашого вебсайту з вашого боку означає вашу згоду з умовами Політики конфіденційності, відповідно до наших змін та доповнень, і з цієї причини, вам слід регулярно переглядати цю Політику конфіденційності для забезпечення того, що ви знаєте умови цієї політики.
      </p>
      <p className="text-lg text-gray-700 mb-4">
        У разі виникнення питань щодо політики конфіденційності просимо надіслати повідомлення електронної пошти на адресу: adclavumcleaning@gmail.com
      </p>
    </div>
  );
}
